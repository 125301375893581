<template>
  <div class="relative container mx-auto  text-blue h-full" id="app">

        <router-view class="relative"/>

  </div>
</template>

<script type="text/javascript">
// import NavBar from "@/components/NavBar.vue";
export default {
  components: {
    //NavBar,
  },
};
</script>

<style>


html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  height: 100%;
}


</style>