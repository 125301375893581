<template>
  <main class="comingsoon h-full">

  </main>
</template>

<script>


export default {
  name: 'ComingSoon',
  components: {
  },
  methods : {

  },
  computed : {
        
  },
  data() {
    return {

    }
  },
  updated() {
    

  },
  mounted() {
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



.comingsoon {
    background-image: url('~@/assets/waterschool-desktop.png'); 
    background-color: white;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;    
}

@media screen and (min-width: 420px) {
} 

@media screen and (min-width: 640px) {
} 

@media screen and (min-width: 768px) {
} 

@media screen and (min-width: 800px) {
} 

@media screen and (min-width: 1024px) {
} 

@media screen and (min-width: 1280px) {
} 


@media screen and (min-width: 1536px) {
} 


</style>

