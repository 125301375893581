import gql from 'graphql-tag'


export const GET_CARETAKER_TYPES_QUERY = gql`
  query getCaretakerTypes{
    caretakerTypes {
      nodes {
        name
        slug
        description
      }
    }
}`;

export const GET_PLOT_QUERY = gql`
  query getPlot($plot: [String]!) {
    plotTags(where: {name: $plot}) {
      nodes {
        communityMembers(first: 99) {
          edges {
            node {
              databaseId
              communityMemberDetails {
                initials,
                caretakerType {
                  name
                }
              }
            }
          }
        }
        plots {
          nodes {
            title
            content
          }
        }
        caretakers {
          nodes {
            uri
            caretakerTypes {
              nodes {
                name
              }
            }
          }
        }
      }
    }
 }`;

// these need to be filtered on caretakerType
// the databaseIds are used to retrieve the content
export const GET_PEOPLE_IDS_PLOT_QUERY = gql`
  query getPeoplePlot($plot: [String]!){
  plotTags(where: {name: $plot}) {
    nodes {
      communityMembers(first: 99) {
        nodes {
          databaseId
          communityMemberDetails {
            caretakerType {
              name
            }
          }
        }
      }
      caretakers (first:99) {
        nodes {
          databaseId
          caretakerDetails {
            caretakerType {
              name
            }
          }
        }
      }
    }
  }
}`;

export const GET_PEOPLE_CONTENT_PLOT_QUERY = gql`
  query getContentPlot($id: ID!,$ids: [ID]! ){
    communityMembers(where: {in: $ids}, first:99) {
      nodes  {
        databaseId
        title
        content
        communityMemberDetails {
          author,
          projectNumber
        }
      }
    }
    caretaker(id: $id, idType: DATABASE_ID) {
        title
        content
    }
}`;

export const GET_PAGE_QUERY = gql`
  query getPage($id: ID!){
    page(id: $id, idType: URI) {
      content
      title
    }
}`;

export const GET_LAST_POST_QUERY = gql`
  query getLastPost{
    posts(last: 1) {
      nodes {
        content
        title
        date
      }
    }
}`;