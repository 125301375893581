<template>
  <main class="h-full">
      <info-popup v-if="infopopupvisible" @close="hideInfo" />
      <div class="w-full absolute flex flex-row pl-1 bg-white bg-opacity-60 z-10">
        <div class="w-3/6">
          <div class="text-indigo text-2xl">
            WATERSCHOOL M4H+ 
            <br/><br/>
            <!-- <br/>May-July 2021 -->
          </div>
          <div class="text-6xl sm:text-6.5xl font-wsbook -ml-2 pt-0 leading-none">
            DOWN
          </div>
        </div>
        <div class="w-3/6 flex justify-end">
          <div class="text-sm sm:text-base inline-block pr-5 leading-snug sm:leading-snug font-wsbook mt-1">
            International 
            <br/>Architecture 
            <br/>Biennale 
            <br/>Rotterdam 
            <br/><a class="font-wsbold" href="http://www.iabr.nl">iabr.nl</a>
          </div>
          <div class="w-10 flex flex-col justify-around">
            <div class="">
              <router-link to="/about">
                <img class="w-full h-full" src="@/assets/MENU_icon.svg" />
              </router-link>
              </div>
            <div class="bg-indigo h-10 flex items-center justify-center text-white text-4xl cursor-pointer" @click="showInfo">i</div>
          </div>
        </div>
      </div>
      <!-- <div class="h-full w-full absolute z-30 flex justify-center items-center">
            <div class="text-center p-4 lg:p-8 bg-wssalmon text-base lg:text-2xl  ml-14 -mt-2">
            IABR - DOWN TO EARTH<br/>
            WATERSCHOOL M4H+<br/>
            will be open to the public <br/>
            from Wednesday june 9</div>
      </div> -->
      <div class="h-full w-full absolute z-10 pt-28 sm:pt-32 overflow-hidden pointer-events-none">
        <div class="grid arrows">
          <div class="arrow arrow-left ">
            <img class="" src="@/assets/arrow-anim.gif" @click="panMap('left')" />
          </div>
          <div class="arrow arrow-right">
            <img class=" transform rotate-180" src="@/assets/arrow-anim.gif" @click="panMap('right')" />
          </div>
          <!-- <div class="arrow arrow-down ">
            <img class=" transform -rotate-90" src="@/assets/arrow-anim.gif"  @click="panMap('down')"/>              
          </div> -->
        </div>
      </div>
      <div ref="map" class="map h-full w-full overflow-auto absolute z-0 pt-28 sm:pt-32 ">
        <div >
          <div ref="dots" class="grid dots">
              <div v-for="index in gridh*gridw" :key="index" class="cell">
                  <router-link v-if="data[index-1] && data[index-1].type === 'caretaker'" :to="'/plots/' + data[index-1].plot + '/caretakers/' + data[index-1].caretaker_type">
                    <img  :src="getImagePath(index)" />
                  </router-link>
                  <img v-else :src="getImagePath(index)" />
              </div>
          </div>
          <div class="grid plots">
            <div 
                v-for="(plotname,index) in plots"  
                  :key="index" 
                  :id="plotname"
                  :class="plotname"
                  :ref="plotname"
                  class="plot"
            >
                <router-link :to="'/plots/' + plotname">
                  <img class="w-full h-full" :src="`/static/${plotname}.svg`" :alt="plotname" />
                </router-link>
            </div>
          </div>
        </div>        
      </div>
      <div class="leading-none text-6xl sm:text-6.5xl pointer-events-none w-full text-right absolute font-wsbook bg-white bg-opacity-60 z-10 bottom-0 -right-1">
          TO EARTH</div>
    </main>
</template>

<script>

import json from '@/assets/home-locations.json';
import InfoPopup from '../components/InfoPopup.vue';

export default {
  name: 'Home',
  components: {
    InfoPopup
  },
  methods : {
    showInfo() {
      this.infopopupvisible = true;
    },
    hideInfo() {
      this.infopopupvisible = false;
    },
    panMap(direction) {

      let map = this.$refs.map;
      let dots = this.$refs.dots;

      // calculate size of a cell
      let cellw = Math.ceil(dots.clientWidth / this.gridw);
      // let cellh = dots.clientHeight / this.gridh;

      let pos = map.scrollLeft;

      if (direction === "left") {
         pos -= cellw;
      }
      else if (direction === "right") {
         pos += cellw;
      }

      map.scrollTo({
        left: pos,
        behavior: 'smooth'
      });
    },
    gotoPage(index) {
        // because I expected the loop to start at 0
      index = index - 1;
      if (this.data[index]) {
        let specs = this.data[index];
        if (specs.type === "caretaker"){
          return '/static/communitypeople.svg';//'@/assets/communitymember.svg';
        }
      }
    },
    getImagePath(index) {
        // because I expected the loop to start at 0
        index = index - 1;
        if (this.data[index]) {
        let specs = this.data[index];
        if (specs.type === "member"){
          return '/static/communitypeople.svg';//'@/assets/communitymember.svg';
        }
        else {
          return '/static/' + specs.caretaker_type + '.svg'
        }
      }
      else {
        return '/static/people.svg';
      }
    }
  },
  computed : {
        
  },
  data() {
    return {
      infopopupvisible: false,
      plotContent: [],
      error: null,  
      plots : [
        "plot001",
        "plot002",
        "plot003",
        "plot004",
        "plot005",
        "plot006",
      ],    
      gridw: 35,
      gridh: 12,
      data: json
    }
  },
  updated() {
    

  },
  mounted() {
    //this.$nextTick(function () {
      if (this.$route.params.plot) {
        let el = this.$el.querySelector("#" + this.$route.params.plot);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        } 
      }
    //})
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* :target {
  display: block;
  position: relative;
  top: -120px; 
  visibility: hidden;
} */

.map {
  position: relative;
  /* border:1px solid black;
  margin-top: 200px; */

  /* height: -webkit-fill-available; */

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  scroll-snap-type: x mandatory;
}

.map::-webkit-scrollbar {
  display: none;
}

.grid {
  width:1835px;
  height: 628px;
  display: grid;
  grid-template-columns: repeat(35, minmax(0, 1fr));
  grid-template-rows: repeat(12, minmax(0, 1fr));
  gap: 9px 8px;
  /* transform: scale(.2);
  transform-origin: top left; */
}

.cell {
  scroll-snap-align: start;
}

.arrows {

  position: absolute;
  z-index: 5;

}

.dots {
  position: absolute;
  z-index: 10;
}

.arrows {
   /* width:1808px; */
  pointer-events: none;
  position: absolute;
  z-index: 30;
  grid-template-areas:
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    "arrow-left . . . . . arrow-right . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . arrow-down . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";
}

@media screen and (min-width: 420px) {
  .arrows {
    grid-template-areas:
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    "arrow-left . . . . . . arrow-right . . . . . . . . . . . . . .  . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . arrow-down . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";
  }
} 

@media screen and (min-width: 640px) {
  .grid {
    width:1880px;
    height: 643px;

  }
  .arrows {
    grid-template-areas:
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    "arrow-left . . . . . . . . . . arrow-right . . . . . . . . . .  . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . arrow-down . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";  }
} 


@media screen and (min-width: 768px) {
  .grid {
    width:1930px;
    height: 660px;
  }
  .arrows {
    grid-template-areas:
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    "arrow-left . . . . . . . . . . . . arrow-right . . . . . . . .  . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . arrow-down . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";  }
} 

@media screen and (min-width: 800px) {
  .grid {
    width:2080px;
    height: 711px;
  }
  .arrows {
    /* background-color: green; */
    grid-template-areas:
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    "arrow-left . . . . . . . . . . . arrow-right . . . . . . . . .  . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . arrow-down . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";  }
} 

@media screen and (min-width: 1024px) {
  .grid {
    width:2115px;
    height: 723px;
  }
  .arrows {
    grid-template-areas:
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    "arrow-left . . . . . . . . . . . . . . . arrow-right . . . . .  . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . arrow-down . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";  }
} 


@media screen and (min-width: 1280px) {
  .grid {
    width:2245px;
    height: 768px;
  }
  .arrows {

    grid-template-areas:
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    "arrow-left . . . . . . . . . . . . . . . . . . arrow-right . . . .  . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . arrow-down . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";  }
} 


@media screen and (min-width: 1536px) {
  .grid {
    width:2567px;
    height: 878px;
  }
  .arrows {

    grid-template-areas:
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    "arrow-left . . . . . . . . . . . . . . . . . . . arrow-right . . . .  . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . arrow-down . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";  }
} 

.arrow {
  pointer-events: all;
}

.arrow-left { 
  grid-area: arrow-left; 
}
.arrow-right { 
  grid-area: arrow-right; 
  left:-3px;

}
.arrow-down { 
  grid-area: arrow-down; 
}


.plots {
  gap: 5px 5px;
  pointer-events: none;
  position: absolute;
  z-index: 20;
  grid-template-areas:
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . plot001 plot001 plot001 . . ."
    ". . . . . . . . plot005 plot005 plot005 plot005 plot005 . . . . . . . . plot003 plot003 . . . . . . plot001 plot001 plot001 . . ."
    ". . . . . . . . plot005 plot005 plot005 plot005 plot005 . . . . . . . . plot003 plot003 . . . . . . plot001 plot001 plot001 . . ."
    ". . . . . . . . plot005 plot005 plot005 plot005 plot005 . . plot004 plot004 plot004 . . . plot003 plot003 . . . . . . . . . . . ."
    ". . . . . . . . plot005 plot005 plot005 plot005 plot005 . . plot004 plot004 plot004 . . . plot003 plot003 . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . plot004 plot004 plot004 . . . . . . plot002 plot002 plot002 plot002 . . . . . . ."
    ". . . . plot006 plot006 plot006 plot006 plot006 . . . . . . . . . . . . . . . plot002 plot002 plot002 plot002 . . . . . . ."
    ". . . . plot006 plot006 plot006 plot006 plot006 . . . . . . . . . . . . . . . plot002 plot002 plot002 plot002 . . . . . . ."
    ". . . . plot006 plot006 plot006 plot006 plot006 . . . . . . . . . . . . . . . plot002 plot002 plot002 plot002 . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
    ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";
    
}

.plot {
  background-color: white;
  pointer-events: all;
}

.plot001 { 
  grid-area: plot001; 
}

.plot002 { 
  grid-area: plot002; 
}

.plot003 { 
  grid-area: plot003; 
}

.plot004 { 
  grid-area: plot004; 
}

.plot005 { 
  grid-area: plot005; 
}

.plot006 { 
  grid-area: plot006; 
}

.cell {
  /* background-color: aquamarine;
  border:1px solid black; */
}

</style>

