<template>
  <section class="absolute w-full h-full z-50 flex items-center justify-center">
    <div class="w-3/4 h-2/4 bg-wssalmon absolute p-2 flex flex-col">
      <div class="flex flex-row">
        <div class="text-lg">{{post.title}}</div>
        <div class="bg-white w-4 h-4 text-center text-sm leading-tight ml-auto" @click="handleClose">X</div>
      </div>
      <div class="content overflow-y-auto mt-6 text-sm text-black pr-2" v-html="post.content"></div>      
    </div>
  </section>
</template>

<script>

import { GET_LAST_POST_QUERY } from "../constants/graphql";
 
export default {
    name: "InfoPopup",
    components: {

    },
    methods: {
      handleClose() {
        this.$emit("close");
      }
    },
    mounted() {
    },
    data() {
    return {
      post: {
              title: '',
              content: ''
      },
      error: null,
    };
  },
    apollo: {
      post: {
        // graphql query
        query: GET_LAST_POST_QUERY,
        update(data) {
          return data.posts.nodes[0];
        },
        //  update: data => data.hello,
        error(error) {
          this.error = JSON.stringify(error.message);
        },
      },
  },
}

</script>

<style scoped>
 
.content::-webkit-scrollbar {
  width: 5px;
}
 
.content::-webkit-scrollbar-thumb {
  background: #cccccc;
  box-shadow: 3px 3px 6px #00000016;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.content::-webkit-scrollbar-track {
  border:1px solid black;
}


</style>